import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import * as XLSX from 'xlsx';

// import emailjs from 'emailjs-com';

const Projects = () => {
    const [excelData, setExcelData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        const response = await fetch('/project.xlsx'); // Fetch the Excel file from the public folder
        const data = await response.arrayBuffer(); // Convert response to array buffer
        const workbook = XLSX.read(data, { type: 'array' }); // Read the Excel file
        const sheetName = workbook.SheetNames[0]; // Assume first sheet
        const sheet = workbook.Sheets[sheetName]; // Get the first sheet
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Convert sheet to JSON
        setExcelData(jsonData); // Set the Excel data to state
      };
  
      fetchData();
    }, []); // Empty dependency array ensures this effect runs only once
  
    
    return (
        <>
            <div>
                <NavBar />
            </div>
            <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
                <div className="overflow-x-auto">
                <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Projects</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
                        Here are the projects that the organization has executed.
                         </h2>
                    </div>
                    </section>
                <table className="w-full table-auto border-collapse border">
        <thead>
          <tr>
            {excelData.length > 0 && excelData[0].map((cell, index) => (
              <th key={index} className="border px-4 py-2">{cell}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {excelData.length > 1 && excelData.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className="border px-4 py-2">{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      </div>

               
                        
                </div>
            </div>
            <Footer />
        </>


    )
}

export default Projects;
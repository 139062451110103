import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
// import emailjs from 'emailjs-com';

import Services from '../components/Services';

const Service1 = () => {
   
    return (
        <>
            <div>
                <NavBar />
            </div>
            <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">

                <div id="services" className="bg-gray-100 py-12" >
                <Services />
        </div>
        <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Workin Area</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Following area the focus working area.</h2>
                    </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <div className="section">
    
    <h2 className="text-3xl  text-green-900 font-black" >1. Geographic Information System</h2>
    <ol>
      <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>1.1 DGPS / GPS / Mobile-Based Survey and Data Collection</p>
        <p className='my-3 text-l text-gray-600 font-semibold'style={{ marginLeft: '2rem' }}>Utilizing advanced technologies for accurate and efficient surveying and data collection.</p>
      </li>
      <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>1.2 Satellite Image Processing</p>
        <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Analysis and interpretation of satellite imagery for various applications.</p>
      </li>
      <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>1.3 Data Management and Analysis</p>
        <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Expert handling and analysis of data to derive valuable insights.</p>
      </li>
      <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>1.4 Preparation of Base Map / High-Resolution Map / Thematic Maps</p>
        <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Creation of detailed maps tailored to specific project requirements.</p>
      </li>
      <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>1.5 Metric Addressing System</p>
        <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Establishment of systematic addressing systems for efficient navigation and location referencing.</p>
      </li>
      <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>1.6 Web App for Digital Profile</p>
        <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Development of web-based applications for digital profiling and data visualization.</p>
      </li>
      <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>1.7 Drone Survey/Mapping</p>
        <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Utilization of drone technology for aerial surveys and mapping, providing detailed and up-to-date information.</p>
      </li>
      <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>1.8 Web GIS/Geoportal</p>
        <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Development of web-based Geographic Information Systems (GIS) and geoportals for seamless data sharing and analysis.</p>
      </li>
      <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>1.9 GIS Training</p>
        <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>GIS training programs designed to equip individuals and organizations with the knowledge and skills needed to leverage Geographic Information Systems effectively.</p>
      </li>
    </ol>
  </div>
                                {/* <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={planning} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Planning</h2>
                                    <p className="text-md font-medium">
                                        We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.
                                    </p> */}
                                </div>
                            </div>
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <div className="section">
                                        <h2 className="text-3xl  text-green-900 font-black" >2. Planning Services</h2>
                                        <ol>
                                            <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>2.1 Base Line Survey</p>
                                            <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Conducting detailed baseline surveys to establish initial conditions for projects, providing essential data for planning and development.</p>
                                            </li>
                                            <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>2.2 Urban Planning</p>
                                            <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Strategic planning and design solutions for urban areas, aimed at fostering sustainable development, efficient infrastructure, and vibrant communities.</p>
                                            </li>
                                            <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>2.3 Land Use Planning</p>
                                            <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Analyzing land use patterns and recommending optimal land utilization strategies to support balanced growth and resource management.</p>
                                            </li>
                                            <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>2.4 Case Study</p>
                                            <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Conducting in-depth case studies to assess past projects, derive lessons learned, and inform future planning decisions for improved outcomes.</p>
                                            </li>
                                            <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>2.5 Project Benefit Monitoring and Evaluation</p>
                                            <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Monitoring and evaluating project performance to ensure alignment with objectives, maximize benefits, and optimize resource allocation.</p>
                                            </li>
                                            <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>2.6 Rural Development</p>
                                            <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Designing and implementing development initiatives tailored to rural areas, focusing on infrastructure improvement, livelihood enhancement, and community empowerment.</p>
                                            </li>
                                            <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>2.7 Architecture Planning & Design</p>
                                            <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Providing architectural planning and design services that integrate functionality, aesthetics, and sustainability principles into the built environment.</p>
                                            </li>
                                        </ol>
                                        </div>
                                {/* <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={planning} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Planning</h2>
                                    <p className="text-md font-medium">
                                        We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.
                                    </p> */}
                                </div>
                            </div>
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <div className="section">
      <h2 className="text-3xl  text-green-900 font-black" >3. Civil Engineering</h2>
      <ol>
        <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>3.1 Project Preparation</p>
          <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>We assist clients in the initial stages of project development, offering expertise in feasibility studies, site selection, and project planning. Our thorough analysis ensures that projects are viable and well-prepared for subsequent stages.</p>
        </li>
        <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>3.2 Surveys and Investigation</p>
          <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Our team conducts comprehensive surveys and investigations, utilizing state-of-the-art equipment and techniques to gather accurate data. From topographic surveys to geotechnical investigations, we provide the necessary information for informed decision-making.</p>
        </li>
        <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>3.3 Design</p>
          <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Our experienced engineers and designers develop innovative and practical solutions tailored to each project's requirements. Using the latest software and industry best practices, we create detailed designs that optimize functionality, safety, and cost-efficiency.</p>
        </li>
        <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>3.4 Implementation</p>
          <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>From project management to construction supervision, we oversee the implementation phase to ensure that designs are executed to the highest standards. We work closely with contractors and stakeholders to ensure timely delivery and adherence to specifications.</p>
        </li>
      </ol>
      <p>We are committed to delivering excellence in civil engineering. Our integrated approach, combined with our technical expertise and dedication, ensures that projects are completed successfully, meeting our clients' needs and exceeding expectations.</p>
    </div>
                                {/* <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={planning} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Planning</h2>
                                    <p className="text-md font-medium">
                                        We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.
                                    </p> */}
                                </div>
                            </div>
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <div className="section">
                                    <h2 className="text-3xl  text-green-900 font-black" >4. Environment Section</h2>
                                    <ol>
                                        <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>4.1 IEE and EIA (Initial Environmental Examination and Environmental Impact Assessment)</p>
                                        <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>We conduct thorough assessments to evaluate potential environmental impacts of projects, ensuring compliance with regulations and promoting sustainable development practices.</p>
                                        </li>
                                        <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>4.2 Environmental Hazard Assessment</p>
                                        <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>Our team identifies and assesses environmental hazards, such as pollution, contamination, and natural disasters, to minimize risks and protect ecosystems and communities.</p>
                                        </li>
                                        <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>4.3 Environmental Studies & Management</p>
                                        <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>We undertake various environmental studies, including biodiversity assessments, water quality monitoring, and air pollution studies, to understand and manage environmental resources effectively.</p>
                                        </li>
                                        <li><p className="text-xl  text-blue-900 font-semibold" style={{ marginLeft: '1rem' }}>4.4 EI Mitigation Measures (Environmental Impact Mitigation Measures)</p>
                                        <p className='my-3 text-l text-gray-600 font-semibold' style={{ marginLeft: '2rem' }}>We develop and implement mitigation measures to minimize adverse environmental impacts, including pollution control measures, habitat restoration, and waste management strategies.</p>
                                        </li>
                                    </ol>
                                    <p>We are committed to promoting environmental stewardship and sustainable practices in every project we undertake. Our team of environmental experts works closely with clients to develop tailored solutions that meet regulatory requirements and promote environmental conservation.</p>
                                    </div>
                                {/* <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={planning} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Planning</h2>
                                    <p className="text-md font-medium">
                                        We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.
                                    </p> */}
                                </div>
                            </div>
                            
        <div className="card">
    
    </div>
    
    
    
  </div>


                        
                </div>
            </div>
            <Footer />
        </>


    )
}

export default Service1;
import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
// import emailjs from 'emailjs-com';

import umesh from '../images/Umesh_Kunwar.jpg';
import sharmila from '../images/Sharmila_Khatiwada.jpg';
import archan from '../images/archan.jpg'
import suman from '../images/suman.png'
import tulsi from '../images/tulsi.png'
import shreya from '../images/shreya.jpeg';
import tara from '../images/tara.jpeg';
import anil from '../images/anil.jpg';

const AboutUs = () => {
   
    return (
        <>
            <div>
                <NavBar />
            </div>
            <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">

                <div id="services" className="bg-gray-100 py-12" >
                <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">About Us</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-justify text-xl lg:text-2xl font-semibold text-blue-900">
                        
                        {/* Nepal is a developing nation. We need a speedy development; 
                        however proper care must be given for the environment as well. 
                        With the goal to sustainably develop Nepal, Remedy Engineering Pvt. Ltd. 
                        was founded with the support of highly qualified experts and advisors in 2017. 
                        This firm provides its services in the wide range of areas of engineering 
                        including project identification, Planning, geospatial solutions, 
                        environmental assessment, pre-feasibility, detailed feasibility, detailed survey 
                        and design, construction, project planning and preparation, technical assistance, developer and valuation, 
                        and organizing trainings.
                    <br />
                        Remedy Engineering Pvt. Ltd. is a company dedicated to 
                        providing quality service and products, equipped with resourceful professionals representing a broad 
                        spectrum of expertise. Well organized and structured company which is capable of and resourceful in 
                        handling all kinds of developmental projects in a comprehensive manner. REMEDY creates partnerships 
                        with our clients. We are committed to fulfilling every requirement of our client, working together 
                        as a cohesive unit in order to guarantee satisfaction. REMEDY offers reliability and trustworthiness. 
                        It aims at promoting goodwill and cordial relationships with its clients through the most efficient service.
                        <br />
                        “Our aim is to use our professional responsibilities to the satisfaction of our clients.” Managed by a 
                        group of young, dynamic and dedicated engineers and experts in different field, REMEDY works with diverse 
                        talents and technologies, planning, co-ordination and guiding their efforts to achieve a smooth on-schedule 
                        performance. We are client centric and offer services of superb quality based on our accumulated, extensive, 
                        and profound knowledge and experience. */}
                        Remedy Engineering Pvt. Ltd. - Sustainable Development Experts
                        Providing comprehensive engineering services since 2017, including 
                        project planning, environmental assessment, and technical assistance. 
                        Our team of qualified professionals ensures quality and reliability in every project. 
                        Client satisfaction is our priority.
  </h2>
                    </div>
                    </section>
                    <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                    <h1 className="mb-5 md:text-5xl text-3xl font-bold uppercase text-green-900 text-center"> MEET THE TEAM</h1>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-green-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Here is our Core Team of Expert</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={sharmila} />
                                <h2 className="font-semibold text-2xl text-center">Sharmila Khatiwada</h2>
                                    <h4 className="font-semibold text-x text-center">CEO</h4>
                                    <p className="text-md font-medium">
                                        Ms. Khatiwada has done Master degree in english and socilogy. She has been associated with this organization since its establishment. 
                                        She has more than 12 years of experience as a Sociologist.
                                        
                                    </p>
                                </div>
                            </div>
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={umesh} />
                                    <h2 className="font-semibold text-2xl text-center">Er. Umesh Kunwar</h2>
                                    <h4 className="font-semibold text-x text-center">GIS Manager</h4>
                                    <p className="text-md font-medium">
                                       Er. Kunwar has done his master degree in photogrametry and geoinfomatic in 2015 and working in this organization since its Establishment. 
                                       His has more than 13 years of gerneral work experience  and more than 8 years of experience in GIS.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={suman} />
                                    <h2 className="font-semibold text-2xl text-center">Suman Kumar Karna</h2>
                                    <h4 className="font-semibold text-x text-center">Urban Planner</h4>
                                    <p className="text-md font-medium">
                                       Mr. Karna has done his master degree in Urban Planning in 2008 and has 15+ years of experience in urban planning. He is the team leader
                                        of most of the urban planning project of the organization. He leades the urban planning section of our organization as a consultant.
                                    </p>
                                </div>
                            </div> <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={anil} />
                                    <h2 className="font-semibold text-2xl text-center">Mr. Anil Kumar Chaudhari</h2>
                                    <h4 className="font-semibold text-x text-center">Environmentalist</h4>
                                    <p className="text-md font-medium">
                                        Ms. Chaudhari has done her master degree in Environmental. He has more than 15+ experience in environment sector. 
                                        He leades the environment related consulting projects of our organization as a consultant.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={shreya} />
                                    <h2 className="font-semibold text-2xl text-center">Er. Shreya Maharjan</h2>
                                    <h4 className="font-semibold text-x text-center">Civil Engineer/ GIS Technician</h4>
                                    <p className="text-md font-medium">
                                        Ms. Maharjan has done her bacholer degree in civil engineering and master degree in construction managment. She has good experience in GIS as well. She
                                        was associated with this organization in 2018. She is leading Civil Engineering Section as well as assisting GIS team.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={tara} />
                                <h2 className="font-semibold text-2xl text-center">Tara Kunwar</h2>
                                    <h4 className="font-semibold text-x text-center">Sociologist</h4>
                                    <p className="text-md font-medium">
                                       Ms. Kunwar has doen master degree in Political Science. She is working with this organization since 2018. She leads the field enuminators in data 
                                       collection.
                                    </p>
                                </div>
                            </div> 

                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={tulsi} />
                                    <h2 className="font-semibold text-2xl text-center">Tulsiram Bhattrai</h2>
                                    <h4 className="font-semibold text-x text-center">Accountant</h4>
                                    <p className="text-md font-medium">
                                     Mr. Bhattrai look after organization account. He is associated with the organization since its establishment.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={archan} />
                                <h2 className="font-semibold text-2xl text-center">Archan Acharya</h2>
                                    <h4 className="font-semibold text-x text-center">Human Resource Manager</h4>
                                    <p className="text-md font-medium">
                                        Mr. Acharya oversee all aspects of human resources, including recruitment, employee relations, training, and 
                                        compliance, to ensure a positive work environment and legal adherence.
                                    </p>
                                </div>
                            </div> 
                            
                                              
                        </div>
                    </div>
            </section>
        </div>
        
        


                        
                </div>
            </div>
            <Footer />
        </>


    )
}

export default AboutUs;